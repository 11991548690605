import { render, staticRenderFns } from "./WagtailPage.vue?vue&type=template&id=25ac3e49&scoped=true&"
import script from "./WagtailPage.vue?vue&type=script&lang=js&"
export * from "./WagtailPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25ac3e49",
  null
  
)

export default component.exports