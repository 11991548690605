<template>
  <div class="pano">
    <PanoramaLoading class="pano" />
    <div class="pano" ref="panocontainer" tabindex="-1"></div>
    <slot v-if="scene !== undefined" />
    <div v-if="scene !== undefined && rectConfigEnabled">
      <MarzipanoRectConfigurator
        v-if="scene !== undefined"
        class="controls"
        :scene="scene"
        @changed="configuratorChanged"
        tabindex="-1"
      />
      <MarzipanoRectHotspot
        v-if="configuratorHotspot !== undefined"
        :yaw="configuratorHotspot.yaw"
        :pitch="configuratorHotspot.pitch"
        :rotX="configuratorHotspot.rotX"
        :rotY="configuratorHotspot.rotY"
        :rotZ="configuratorHotspot.rotZ"
        :width="configuratorHotspot.width"
        :height="configuratorHotspot.height"
        :debug="true"
        :scene="scene"
      />
    </div>
  </div>
</template>

<script>
import Marzipano from "marzipano";
import MarzipanoRectHotspot from "./MarzipanoRectHotspot";
import MarzipanoRectConfigurator from "./MarzipanoRectConfigurator";
import PanoramaLoading from "./PanoramaLoading.vue";

import ResizeSensor from "css-element-queries/src/ResizeSensor";

export default {
  name: "Marzipano",
  props: ["typeOf", "tileUrl", "data", "ready", "sasKey","rectConfigEnabled", "minYaw", "maxYaw"],
  data: () => ({
    dialog: true,
    firstClick: true,
    rotx: 0.0,
    roty: 0.0,
    scene: undefined,
    configuratorHotspot: undefined,
  }),
  components: {
    MarzipanoRectHotspot,
    MarzipanoRectConfigurator,
    PanoramaLoading,
  },
  mounted: function () {
    console.log(this.data,this.maxYaw, this.minYaw , "data from marzipano.vue")
    // console.log(this.typeOf, "typeOf")
    let pano = this.$refs.panocontainer;

    var viewer = new Marzipano.Viewer(
      // document.getElementById("panorama-container"),
      this.$refs.panocontainer,
      { stage: { progressive: true } }
    );

    new ResizeSensor(pano, function () {
      viewer.updateSize();
    });

    var view, geometry, source, limiter;

    if (this.typeOf == "equirect") {

      // Create source.
      source = Marzipano.ImageUrlSource.fromString(this.data.file);

      // Create geometry.
      geometry = new Marzipano.EquirectGeometry([{ width: this.data.width }]);

      // Create view.
             Marzipano.RectilinearView.limit.yaw(Math.PI * (this.minYaw / 180), Math.PI * (this.maxYaw / 180)),

    //Commented Code
      // limiter = Marzipano.RectilinearView.limit.traditional(
      //   1024,
      //   (Math.PI * (this.minYaw / 180), Math.PI * (this.maxYaw / 180))
      // );
      view = new Marzipano.RectilinearView({ yaw: Math.PI }, limiter);
    } else {
      var urlPrefix = this.tileUrl;

      source = Marzipano.ImageUrlSource.fromString(
        urlPrefix + "/{z}/{f}/{y}/{x}.jpg?" + this.sasKey,
        { cubeMapPreviewUrl: urlPrefix + "/preview.jpg?" + this.sasKey }
      );

      // Create geometry.
      geometry = new Marzipano.CubeGeometry(this.data.levels);

      // limiter = Marzipano.RectilinearView.limit.vfov(
      //   Math.PI * (this.minYaw / 180), Math.PI * (this.maxYaw / 180)
      // );

      limiter = Marzipano.util.compose(
        Marzipano.RectilinearView.limit.vfov((40 * Math.PI) / 180,(80 * Math.PI) / 180),
        Marzipano.RectilinearView.limit.yaw(Math.PI * (this.minYaw / 180), Math.PI * (this.maxYaw / 180)),
        Marzipano.RectilinearView.limit.pitch(Math.PI * (-80 / 180), Math.PI * (80 / 180))
      );

      view = new Marzipano.RectilinearView(
        this.data.initialViewParameters,
        limiter
      );
    }
    // Autorotate will start after 3s of idle time
    // viewer.setIdleMovement(5000, autorotate);

    // Create scene.
    this.scene = viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: false,
    });

    // Display scene.
    this.scene.switchTo();

    // Start autorotation immediately

    this.$emit("loaded", this.scene);
  },
  watch: {
    ready() {
      if (this.ready === true) {
        var autorotate = Marzipano.autorotate({
          yawSpeed: 0.025, // Yaw rotation speed
          targetPitch: 0, // Pitch value to converge to
          targetFov: (80 * Math.PI) / 180, // Fov value to converge to
        });

        this.scene.viewer().startMovement(autorotate);
      }
    },
  },
  methods: {
    configuratorChanged(data) {
      this.configuratorHotspot = data;
    },
     lookToConsole(consoleYaw, consolePitch){
      var destinationViewParameters = {
      yaw: (consoleYaw* Math.PI) / 60,
      pitch: (consolePitch * Math.PI) / 60,
      fov: (80 * Math.PI) / 180,
    }
    var options = {
      transitionDuration: 2000,
    };
    this.scene.lookTo(destinationViewParameters, options);
     }
  },
};
</script>


<style scoped>
.pano {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: visible !important;
}

.frame {
  width: 1000px;
  height: 1000px;
  background-color: rgba(255, 0, 0, 0.5);
}

.controls {
  position: absolute;
  left: 10px;
  /* right: 10px; */
  top: 10px;
  z-index: 5;
}
</style>
