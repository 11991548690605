<template>
  <LightboxContentLayout :accessibilityText="data.accessibility_text" :ariaDescription="ariaDescription"
    :captionText="data.caption" :accessibilityTextFlag="accessibilityTextFlag">
    <video :aria-label="accessibilityLabel" id="lightboxvideo" class="video1" controls autoplay>
      <source :src="videoAccessibility" type="video/mp4" />
    </video>
  </LightboxContentLayout>
</template>

<script>

import LightboxContentLayout from './LightboxContentLayout.vue';

export default {
  name: "LightboxVideoCard",
  data: () => ({
    dialog: true,
    isMobile: false,
    windowWidth: window.screen.width,
    isTablet: false,
    isLaptop: false
  }),
  props: [
    "data",
    "accessibilityTextFlag",
    "accessibilityVideoFlag",
    "accessibility",
    "ariaDescription"
  ],
  components: { LightboxContentLayout },
  mounted() {
    console.log(this.data, "video light data content");
  },
  computed: {
    video() {
      if (this.data && this.data.file) return this.data.file;
      return undefined;
    },
    videoAccessibility() {
      if (this.accessibilityMode && this.accessibilityVideoFlag)
        return this.data.accessible_video;
      return this.data.file;
    },
    accessibilityLabel() {
      if (this.data && this.data.file) return this.data.accessibility_text;
      return undefined;
    },
    hasCaption() {
      if (this.data && this.data.caption)
        if (this.data.caption !== undefined) return true;
      return false;
    },
    accessibilityMode() {
      return this.$store.state.wagtail.accessibilityMode;
    },
    hasAccessibleText() {
      if (this.data && this.data.accessibility_text) return true;
      return false
    },
    videoWidth() {
      if (this.accessibilityTextFlag) return 60 + '%'
      if (this.hasAccessibleText &&
        this.hasCaption &&
        this.accessibilityTextFlag == true) return 70 + "%";
      return 100 + '%'
    }

  },
  watch: {
    accessibilityVideoFlag(newValue) {
      console.log(newValue, "value changed video");
      var videoElement = document.getElementById("lightboxvideo");

      if (this.accessibilityVideoFlag) {
        videoElement.pause();
        videoElement.removeAttribute("src");
        videoElement.setAttribute("src", this.data.accessible_video); // empty source
        videoElement.load();
      } else {
        videoElement.pause();
        videoElement.removeAttribute("src");
        videoElement.setAttribute("src", this.data.file); // empty source
        videoElement.load();
      }
    },

  },
  methods: {
    textFlag() {
      console.log("triggered here from image");
      if (this.accessibilityTextFlag == false) {
        this.accessibilityTextFlag = true;
      } else {
        this.accessibilityTextFlag = false;
      }
    },
  },
};
</script>


<style scoped>
.full-screen {
  width: 100%;
  height: 100%;
}

video {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.video1 {
  width: 100%;
  height: 100%;
}
</style>